export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: '返回上一级'
        },
        defaultReturn: {
            type:Boolean,
            default: true
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        back() {
            if (this.defaultReturn) {
                this.$router.go(-1)
            }
            this.$emit('back')
        }
    }
}